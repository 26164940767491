module.exports = {
  houseA: {
    groundPlane: [
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
    ],
    verificationFunction: function (verificationAPI) {
      return verificationAPI.solutionMapMatchesResultMap([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'any',
        'any',
        'any',
        'any',
        '',
        '',
        '',
        '',
        '',
        '',
        'any',
        '',
        '',
        'any',
        '',
        '',
        '',
        '',
        '',
        '',
        'any',
        '',
        '',
        'any',
        '',
        '',
        '',
        '',
        '',
        '',
        'any',
        'any',
        'any',
        'any',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]);
    }.toString(),
    blocksToStore: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseRightC',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseRightB',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseLeftA',
      '',
      '',
      'houseRightA',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseBottomA',
      'houseBottomB',
      'houseBottomC',
      'houseBottomD',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],

    houseBottomRight: [5, 5],
  },
  houseC: {
    groundPlane: [
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
    ],
    groundDecorationPlane: [
      '',
      '',
      '',
      '',
      '',
      'tallGrass',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'tallGrass',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    actionPlane: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseBottomA',
      'houseBottomB',
      'houseBottomC',
      'houseBottomD',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    verificationFunction:
      'function (verificationAPI) {\r\n      return verificationAPI.solutionMapMatchesResultMap(\r\n            [\r\n              "", "", "", "", "", "", "", "", "", "",\r\n              "", "", "", "", "", "", "", "", "", "",\r\n              "", "", "any", "any", "any", "any", "", "", "", "",\r\n              "", "", "any", "", "", "", "any", "", "", "",\r\n              "", "", "any", "any", "", "", "any", "", "", "",\r\n              "", "", "", "any", "", "", "any", "", "", "",\r\n              "", "", "", "any", "any", "any", "any", "", "", "",\r\n              "", "", "", "", "", "", "", "", "", "",\r\n              "", "", "", "", "", "", "", "", "", "",\r\n              "", "", "", "", "", "", "", "", "", ""\r\n            ]);\r\n}',
    startBlocks:
      '<xml><block type="when_run" deletable="false" movable="false"><next><block type="controls_repeat_dropdown"><title name="TIMES" config="2-10">2</title><statement name="DO"><block type="craft_moveForward"><next><block type="craft_placeBlock"><title name="TYPE">planksBirch</title></block></next></block></statement><next><block type="craft_turn"><title name="DIR">left</title><next><block type="craft_moveForward"><next><block type="craft_placeBlock"><title name="TYPE">planksBirch</title><next><block type="craft_turn"><title name="DIR">right</title></block></next></block></next></block></next></block></next></block></next></block></xml>',

    blocksToStore: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseRightC',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseRightB',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseLeftA',
      '',
      '',
      'houseRightA',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseBottomA',
      'houseBottomB',
      'houseBottomC',
      'houseBottomD',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],

    houseBottomRight: [5, 5],
  },
  houseB: {
    groundPlane: [
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'dirtCoarse',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
      'grass',
    ],
    verificationFunction:
      'function (verificationAPI) {\r\n      return verificationAPI.solutionMapMatchesResultMap(\r\n            [\r\n              "", "", "", "", "", "", "", "", "", "",\r\n              "", "", "", "any", "any", "any", "", "", "", "",\r\n              "", "", "", "any", "", "any", "", "", "", "",\r\n              "", "", "", "any", "", "any", "", "", "", "",\r\n              "", "", "", "any", "", "any", "any", "", "", "",\r\n              "", "", "", "any", "", "", "any", "", "", "",\r\n              "", "", "", "any", "", "", "any", "", "", "",\r\n              "", "", "", "any", "any", "any", "any", "", "", "",\r\n              "", "", "", "", "", "", "", "", "", "",\r\n              "", "", "", "", "", "", "", "", "", ""\r\n            ]);\r\n}',
    startBlocks:
      '<xml><block type="when_run" deletable="false" movable="false"><next><block type="controls_repeat_dropdown"><title name="TIMES" config="2-10">6</title><statement name="DO"><block type="craft_moveForward"><next><block type="craft_placeBlock"><title name="TYPE">planksBirch</title></block></next></block></statement></block></next></block></xml>',
    blocksToStore: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseRightC',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseRightB',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseLeftA',
      '',
      '',
      'houseRightA',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseBottomA',
      'houseBottomB',
      'houseBottomC',
      'houseBottomD',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    actionPlane: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'houseBottomA',
      'houseBottomB',
      'houseBottomC',
      'houseBottomD',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    playerStartPosition: [3, 7],

    houseBottomRight: [5, 6],
  },
};
