module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar title = locals.title;\nvar message = locals.message;\n%>\n<h1 class="minecraft-big-yellow-header" id="error-message"><%= title %></h1>\n\n<h2><%= message %></h2>\n\n<div id="close-popup"></div>\n', __filename = "src/craft/code-connection/dialogs/errorMessage.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var title = locals.title;
        var message = locals.message;
        __line = 7;
        __append('\n<h1 class="minecraft-big-yellow-header" id="error-message">');
        __line = 8;
        __append(escapeFn(title));
        __append("</h1>\n\n<h2>");
        __line = 10;
        __append(escapeFn(message));
        __append('</h2>\n\n<div id="close-popup"></div>\n');
        __line = 13;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}