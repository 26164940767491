const MEDIA_URL = '/blockly/media/craft/Icons/';

export default Object.freeze({
  decorations: [
    [MEDIA_URL + 'decorations/beacon.png', 'beacon'],
    [MEDIA_URL + 'decorations/cobblestone_wall.png', 'cobblestone_wall'],
    [
      MEDIA_URL + 'decorations/mossy_cobblestone_wall.png',
      'cobblestone_wall,1',
    ],
    [MEDIA_URL + 'decorations/waterlily.png', 'waterlily'],
    [MEDIA_URL + 'decorations/sealantern.png', 'sealantern'],
    [MEDIA_URL + 'decorations/chorus_plant.png', 'chorus_plant'],
    [MEDIA_URL + 'decorations/chorus_flower.png', 'chorus_flower'],
    [MEDIA_URL + 'decorations/gold_block.png', 'gold_block'],
    [MEDIA_URL + 'decorations/iron_block.png', 'iron_block'],
    [MEDIA_URL + 'decorations/diamond_block.png', 'diamond_block'],
    [MEDIA_URL + 'decorations/lapis_block.png', 'lapis_block'],
    [MEDIA_URL + 'decorations/coal_block.png', 'coal_block'],
    [MEDIA_URL + 'decorations/emerald_block.png', 'emerald_block'],
    [MEDIA_URL + 'decorations/redstone_block.png', 'redstone_block'],
    [MEDIA_URL + 'decorations/snow_layer.png', 'snow_layer'],
    [MEDIA_URL + 'decorations/glass.png', 'glass'],
    [MEDIA_URL + 'decorations/glowstone.png', 'glowstone'],
    [MEDIA_URL + 'decorations/vine.png', 'vine'],
    [MEDIA_URL + 'decorations/ladder.png', 'ladder'],
    [MEDIA_URL + 'decorations/sponge.png', 'sponge'],
    [MEDIA_URL + 'decorations/wet_sponge.png', 'sponge,1'],
    [MEDIA_URL + 'decorations/glass_pane.png', 'glass_pane'],
    [MEDIA_URL + 'decorations/door_wood.png', 'wooden_door'],
    [MEDIA_URL + 'decorations/door_spruce.png', 'spruce_door'],
    [MEDIA_URL + 'decorations/door_birch.png', 'birch_door'],
    [MEDIA_URL + 'decorations/door_jungle.png', 'jungle_door'],
    [MEDIA_URL + 'decorations/door_acacia.png', 'acacia_door'],
    [MEDIA_URL + 'decorations/door_dark_oak.png', 'dark_oak_door'],
    [MEDIA_URL + 'decorations/door_iron.png', 'iron_door'],
    [MEDIA_URL + 'decorations/trapdoor.png', 'trapdoor'],
    [MEDIA_URL + 'decorations/fence_wood.png', 'fence'],
    [MEDIA_URL + 'decorations/fence_spruce.png', 'fence,1'],
    [MEDIA_URL + 'decorations/fence_birch.png', 'fence,2'],
    [MEDIA_URL + 'decorations/fence_jungle.png', 'fence,3'],
    [MEDIA_URL + 'decorations/fence_acacia.png', 'fence,4'],
    [MEDIA_URL + 'decorations/fence_dark_oak.png', 'fence,5'],
    [MEDIA_URL + 'decorations/nether_brick_fence.png', 'nether_brick_fence'],
    [MEDIA_URL + 'decorations/fence_gate.png', 'fence_gate'],
    [MEDIA_URL + 'decorations/spruce_fence_gate.png', 'spruce_fence_gate'],
    [MEDIA_URL + 'decorations/birch_fence_gate.png', 'birch_fence_gate'],
    [MEDIA_URL + 'decorations/jungle_fence_gate.png', 'jungle_fence_gate'],
    [MEDIA_URL + 'decorations/acacia_fence_gate.png', 'acacia_fence_gate'],
    [MEDIA_URL + 'decorations/dark_oak_fence_gate.png', 'dark_oak_fence_gate'],
    [MEDIA_URL + 'decorations/iron_bars.png', 'iron_bars'],
    [MEDIA_URL + 'decorations/bed_white.png', 'bed'],
    [MEDIA_URL + 'decorations/bed_silver.png', 'bed,8'],
    [MEDIA_URL + 'decorations/bed_gray.png', 'bed,7'],
    [MEDIA_URL + 'decorations/bed_black.png', 'bed,15'],
    [MEDIA_URL + 'decorations/bed_brown.png', 'bed,12'],
    [MEDIA_URL + 'decorations/bed_red.png', 'bed,14'],
    [MEDIA_URL + 'decorations/bed_orange.png', 'bed,1'],
    [MEDIA_URL + 'decorations/bed_yellow.png', 'bed,4'],
    [MEDIA_URL + 'decorations/bed_lime.png', 'bed,5'],
    [MEDIA_URL + 'decorations/bed_green.png', 'bed,13'],
    [MEDIA_URL + 'decorations/bed_cyan.png', 'bed,9'],
    [MEDIA_URL + 'decorations/bed_light_blue.png', 'bed,3'],
    [MEDIA_URL + 'decorations/bed_blue.png', 'bed,11'],
    [MEDIA_URL + 'decorations/bed_purple.png', 'bed,10'],
    [MEDIA_URL + 'decorations/bed_magenta.png', 'bed,2'],
    [MEDIA_URL + 'decorations/bed_pink.png', 'bed,6'],
    [MEDIA_URL + 'decorations/bookshelf.png', 'bookshelf'],
    [MEDIA_URL + 'decorations/sign.png', 'sign'],
    [MEDIA_URL + 'decorations/painting.png', 'painting'],
    [MEDIA_URL + 'decorations/item_frame.png', 'frame'],
    [MEDIA_URL + 'decorations/crafting_table.png', 'crafting_table'],
    [MEDIA_URL + 'decorations/stonecutter.png', 'stonecutter'],
    [MEDIA_URL + 'decorations/chest.png', 'chest'],
    [MEDIA_URL + 'decorations/trapped_chest.png', 'trapped_chest'],
    [MEDIA_URL + 'decorations/brewing_stand.png', 'brewing_stand'],
    [MEDIA_URL + 'decorations/cauldron.png', 'cauldron'],
    [MEDIA_URL + 'decorations/noteblock.png', 'noteblock'],
    [MEDIA_URL + 'decorations/end_rod.png', 'end_rod'],
    [MEDIA_URL + 'decorations/end_portal_frame.png', 'end_portal_frame'],
    [MEDIA_URL + 'decorations/shulker_box_white.png', 'shulker_box'],
    [MEDIA_URL + 'decorations/shulker_box_silver.png', 'shulker_box,8'],
    [MEDIA_URL + 'decorations/shulker_box_gray.png', 'shulker_box,7'],
    [MEDIA_URL + 'decorations/shulker_box_black.png', 'shulker_box,15'],
    [MEDIA_URL + 'decorations/shulker_box_brown.png', 'shulker_box,12'],
    [MEDIA_URL + 'decorations/shulker_box_red.png', 'shulker_box,14'],
    [MEDIA_URL + 'decorations/shulker_box_orange.png', 'shulker_box,1'],
    [MEDIA_URL + 'decorations/shulker_box_yellow.png', 'shulker_box,4'],
    [MEDIA_URL + 'decorations/shulker_box_lime.png', 'shulker_box,5'],
    [MEDIA_URL + 'decorations/shulker_box_green.png', 'shulker_box,13'],
    [MEDIA_URL + 'decorations/shulker_box_cyan.png', 'shulker_box,9'],
    [MEDIA_URL + 'decorations/shulker_box_light_blue.png', 'shulker_box,3'],
    [MEDIA_URL + 'decorations/shulker_box_blue.png', 'shulker_box,11'],
    [MEDIA_URL + 'decorations/shulker_box_purple.png', 'shulker_box,10'],
    [MEDIA_URL + 'decorations/shulker_box_magenta.png', 'shulker_box,2'],
    [MEDIA_URL + 'decorations/shulker_box_pink.png', 'shulker_box,6'],
    [MEDIA_URL + 'decorations/anvil.png', 'anvil'],
    [MEDIA_URL + 'decorations/slightly_damaged_anvil.png', 'anvil,1'],
    [MEDIA_URL + 'decorations/very_damaged_anvil.png', 'anvil,2'],
    [MEDIA_URL + 'decorations/flower_dandelion.png', 'yellow_flower'],
    [MEDIA_URL + 'decorations/flower_rose.png', 'red_flower'],
    [MEDIA_URL + 'decorations/flower_blue_orchid.png', 'red_flower,1'],
    [MEDIA_URL + 'decorations/flower_paeonia.png', 'red_flower,2'],
    [MEDIA_URL + 'decorations/flower_houstonia.png', 'red_flower,3'],
    [MEDIA_URL + 'decorations/flower_tulip_red.png', 'red_flower,4'],
    [MEDIA_URL + 'decorations/flower_tulip_orange.png', 'red_flower,5'],
    [MEDIA_URL + 'decorations/flower_tulip_white.png', 'red_flower,6'],
    [MEDIA_URL + 'decorations/flower_tulip_pink.png', 'red_flower,7'],
    [MEDIA_URL + 'decorations/flower_oxeye_daisy.png', 'red_flower,8'],
    [MEDIA_URL + 'decorations/double_plant_sunflower.png', 'double_plant'],
    [MEDIA_URL + 'decorations/double_plant_syringa.png', 'double_plant,1'],
    [MEDIA_URL + 'decorations/double_plant_grass.png', 'double_plant,2'],
    [MEDIA_URL + 'decorations/double_plant_fern.png', 'double_plant,3'],
    [MEDIA_URL + 'decorations/double_plant_rose.png', 'double_plant,4'],
    [MEDIA_URL + 'decorations/double_plant_paeonia.png', 'double_plant,5'],
    [MEDIA_URL + 'decorations/mushroom_brown.png', 'brown_mushroom'],
    [MEDIA_URL + 'decorations/mushroom_red.png', 'red_mushroom'],
    [
      MEDIA_URL + 'decorations/brown_mushroom_block.png',
      'brown_mushroom_block,14',
    ],
    [MEDIA_URL + 'decorations/red_mushroom_block.png', 'red_mushroom_block,14'],
    [
      MEDIA_URL + 'decorations/brown_mushroom_block2.png',
      'brown_mushroom_block',
    ],
    [
      MEDIA_URL + 'decorations/brown_mushroom_block3.png',
      'brown_mushroom_block,15',
    ],
    [MEDIA_URL + 'decorations/cactus.png', 'cactus'],
    [MEDIA_URL + 'decorations/melon_block.png', 'melon_block'],
    [MEDIA_URL + 'decorations/pumpkin.png', 'pumpkin'],
    [MEDIA_URL + 'decorations/lit_pumpkin.png', 'lit_pumpkin'],
    [MEDIA_URL + 'decorations/web.png', 'web'],
    [MEDIA_URL + 'decorations/hay_block.png', 'hay_block'],
    [MEDIA_URL + 'decorations/tallgrass_grass.png', 'tallgrass,1'],
    [MEDIA_URL + 'decorations/tallgrass_fern.png', 'tallgrass'],
    [MEDIA_URL + 'decorations/deadbush.png', 'deadbush'],
    [MEDIA_URL + 'decorations/sapling_oak.png', 'sapling'],
    [MEDIA_URL + 'decorations/sapling_spruce.png', 'sapling,1'],
    [MEDIA_URL + 'decorations/sapling_birch.png', 'sapling,2'],
    [MEDIA_URL + 'decorations/sapling_jungle.png', 'sapling,3'],
    [MEDIA_URL + 'decorations/sapling_acacia.png', 'sapling,4'],
    [MEDIA_URL + 'decorations/sapling_dark_oak.png', 'sapling,5'],
    [MEDIA_URL + 'decorations/leaves_oak.png', 'leaves'],
    [MEDIA_URL + 'decorations/leaves_spruce.png', 'leaves,1'],
    [MEDIA_URL + 'decorations/leaves_birch.png', 'leaves,2'],
    [MEDIA_URL + 'decorations/leaves_jungle.png', 'leaves,3'],
    [MEDIA_URL + 'decorations/leaves_acacia.png', 'leaves2,0'],
    [MEDIA_URL + 'decorations/leaves_dark_oak.png', 'leaves2,1'],
    [
      MEDIA_URL + 'decorations/white_glazed_terracotta.png',
      'white_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/silver_glazed_terracotta.png',
      'silver_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/gray_glazed_terracotta.png',
      'gray_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/black_glazed_terracotta.png',
      'black_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/brown_glazed_terracotta.png',
      'brown_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/red_glazed_terracotta.png',
      'red_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/orange_glazed_terracotta.png',
      'orange_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/yellow_glazed_terracotta.png',
      'yellow_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/lime_glazed_terracotta.png',
      'lime_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/green_glazed_terracotta.png',
      'green_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/cyan_glazed_terracotta.png',
      'cyan_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/light_blue_glazed_terracotta.png',
      'light_blue_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/blue_glazed_terracotta.png',
      'blue_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/purple_glazed_terracotta.png',
      'purple_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/magenta_glazed_terracotta.png',
      'magenta_glazed_terracotta',
    ],
    [
      MEDIA_URL + 'decorations/pink_glazed_terracotta.png',
      'pink_glazed_terracotta',
    ],
    [MEDIA_URL + 'decorations/cake.png', 'cake'],
    [MEDIA_URL + 'decorations/skull_skeleton.png', 'skull'],
    [MEDIA_URL + 'decorations/skull_wither.png', 'skull,1'],
    [MEDIA_URL + 'decorations/skull_zombie.png', 'skull,2'],
    [MEDIA_URL + 'decorations/skull_steve.png', 'skull,3'],
    [MEDIA_URL + 'decorations/skull_creeper.png', 'skull,4'],
    [MEDIA_URL + 'decorations/skull_dragon.png', 'skull,5'],
    [MEDIA_URL + 'decorations/flower_pot.png', 'flower_pot'],
    [MEDIA_URL + 'decorations/stone.png', 'monster_egg'],
    [MEDIA_URL + 'decorations/cobblestone.png', 'monster_egg,1'],
    [MEDIA_URL + 'decorations/stonebrick.png', 'monster_egg,2'],
    [MEDIA_URL + 'decorations/mossystonebrick.png', 'monster_egg,3'],
    [MEDIA_URL + 'decorations/crackedstonebrick.png', 'monster_egg,4'],
    [MEDIA_URL + 'decorations/chiseledstonebrick.png', 'monster_egg,5'],
    [MEDIA_URL + 'decorations/dragon_egg.png', 'dragon_egg'],
    [MEDIA_URL + 'decorations/end_crystal.png', 'end_crystal'],
    [MEDIA_URL + 'decorations/mob_spawner.png', 'mob_spawner'],
    [MEDIA_URL + 'decorations/enchanting_table.png', 'enchanting_table'],
    [MEDIA_URL + 'decorations/slime.png', 'slime'],
    [MEDIA_URL + 'decorations/ender_chest.png', 'ender_chest'],
    [MEDIA_URL + 'decorations/chalkboard_small.png', 'board'],
    [MEDIA_URL + 'decorations/chalkboard_medium.png', 'board,1'],
    [MEDIA_URL + 'decorations/chalkboard_large.png', 'board,2'],
    [MEDIA_URL + 'decorations/wool_white.png', 'wool'],
    [MEDIA_URL + 'decorations/wool_silver.png', 'wool,8'],
    [MEDIA_URL + 'decorations/wool_gray.png', 'wool,7'],
    [MEDIA_URL + 'decorations/wool_black.png', 'wool,15'],
    [MEDIA_URL + 'decorations/wool_brown.png', 'wool,12'],
    [MEDIA_URL + 'decorations/wool_red.png', 'wool,14'],
    [MEDIA_URL + 'decorations/wool_orange.png', 'wool,1'],
    [MEDIA_URL + 'decorations/wool_yellow.png', 'wool,4'],
    [MEDIA_URL + 'decorations/wool_lime.png', 'wool,5'],
    [MEDIA_URL + 'decorations/wool_green.png', 'wool,13'],
    [MEDIA_URL + 'decorations/wool_cyan.png', 'wool,9'],
    [MEDIA_URL + 'decorations/wool_light_blue.png', 'wool,3'],
    [MEDIA_URL + 'decorations/wool_blue.png', 'wool,11'],
    [MEDIA_URL + 'decorations/wool_purple.png', 'wool,10'],
    [MEDIA_URL + 'decorations/wool_magenta.png', 'wool,2'],
    [MEDIA_URL + 'decorations/wool_pink.png', 'wool,6'],
    [MEDIA_URL + 'decorations/carpet_white.png', 'carpet'],
    [MEDIA_URL + 'decorations/carpet_silver.png', 'carpet,8'],
    [MEDIA_URL + 'decorations/carpet_gray.png', 'carpet,7'],
    [MEDIA_URL + 'decorations/carpet_black.png', 'carpet,15'],
    [MEDIA_URL + 'decorations/carpet_brown.png', 'carpet,12'],
    [MEDIA_URL + 'decorations/carpet_red.png', 'carpet,14'],
    [MEDIA_URL + 'decorations/carpet_orange.png', 'carpet,1'],
    [MEDIA_URL + 'decorations/carpet_yellow.png', 'carpet,4'],
    [MEDIA_URL + 'decorations/carpet_lime.png', 'carpet,5'],
    [MEDIA_URL + 'decorations/carpet_green.png', 'carpet,13'],
    [MEDIA_URL + 'decorations/carpet_cyan.png', 'carpet,9'],
    [MEDIA_URL + 'decorations/carpet_light_blue.png', 'carpet,3'],
    [MEDIA_URL + 'decorations/carpet_blue.png', 'carpet,11'],
    [MEDIA_URL + 'decorations/carpet_purple.png', 'carpet,10'],
    [MEDIA_URL + 'decorations/carpet_magenta.png', 'carpet,2'],
    [MEDIA_URL + 'decorations/carpet_pink.png', 'carpet,6'],
    [MEDIA_URL + 'decorations/concretepowder_white.png', 'concretepowder'],
    [MEDIA_URL + 'decorations/concretepowder_silver.png', 'concretepowder,8'],
    [MEDIA_URL + 'decorations/concretepowder_gray.png', 'concretepowder,7'],
    [MEDIA_URL + 'decorations/concretepowder_black.png', 'concretepowder,15'],
    [MEDIA_URL + 'decorations/concretepowder_brown.png', 'concretepowder,12'],
    [MEDIA_URL + 'decorations/concretepowder_red.png', 'concretepowder,14'],
    [MEDIA_URL + 'decorations/concretepowder_orange.png', 'concretepowder,1'],
    [MEDIA_URL + 'decorations/concretepowder_yellow.png', 'concretepowder,4'],
    [MEDIA_URL + 'decorations/concretepowder_lime.png', 'concretepowder,5'],
    [MEDIA_URL + 'decorations/concretepowder_green.png', 'concretepowder,13'],
    [MEDIA_URL + 'decorations/concretepowder_cyan.png', 'concretepowder,9'],
    [
      MEDIA_URL + 'decorations/concretepowder_light_blue.png',
      'concretepowder,3',
    ],
    [MEDIA_URL + 'decorations/concretepowder_blue.png', 'concretepowder,11'],
    [MEDIA_URL + 'decorations/concretepowder_purple.png', 'concretepowder,10'],
    [MEDIA_URL + 'decorations/concretepowder_magenta.png', 'concretepowder,2'],
    [MEDIA_URL + 'decorations/concretepowder_pink.png', 'concretepowder,6'],
    [MEDIA_URL + 'decorations/concrete_white.png', 'concrete'],
    [MEDIA_URL + 'decorations/concrete_silver.png', 'concrete,8'],
    [MEDIA_URL + 'decorations/concrete_gray.png', 'concrete,7'],
    [MEDIA_URL + 'decorations/concrete_black.png', 'concrete,15'],
    [MEDIA_URL + 'decorations/concrete_brown.png', 'concrete,12'],
    [MEDIA_URL + 'decorations/concrete_red.png', 'concrete,14'],
    [MEDIA_URL + 'decorations/concrete_orange.png', 'concrete,1'],
    [MEDIA_URL + 'decorations/concrete_yellow.png', 'concrete,4'],
    [MEDIA_URL + 'decorations/concrete_lime.png', 'concrete,5'],
    [MEDIA_URL + 'decorations/concrete_green.png', 'concrete,13'],
    [MEDIA_URL + 'decorations/concrete_cyan.png', 'concrete,9'],
    [MEDIA_URL + 'decorations/concrete_light_blue.png', 'concrete,3'],
    [MEDIA_URL + 'decorations/concrete_blue.png', 'concrete,11'],
    [MEDIA_URL + 'decorations/concrete_purple.png', 'concrete,10'],
    [MEDIA_URL + 'decorations/concrete_magenta.png', 'concrete,2'],
    [MEDIA_URL + 'decorations/concrete_pink.png', 'concrete,6'],
  ],
  miscellaneous: [
    [MEDIA_URL + 'miscellaneous/coal.png', 'coal'],
    [MEDIA_URL + 'miscellaneous/charcoal.png', 'coal,1'],
    [MEDIA_URL + 'miscellaneous/diamond.png', 'diamond'],
    [MEDIA_URL + 'miscellaneous/iron_ingot.png', 'iron_ingot'],
    [MEDIA_URL + 'miscellaneous/gold_ingot.png', 'gold_ingot'],
    [MEDIA_URL + 'miscellaneous/emerald.png', 'emerald'],
    [MEDIA_URL + 'miscellaneous/stick.png', 'stick'],
    [MEDIA_URL + 'miscellaneous/bowl.png', 'bowl'],
    [MEDIA_URL + 'miscellaneous/string.png', 'string'],
    [MEDIA_URL + 'miscellaneous/feather.png', 'feather'],
    [MEDIA_URL + 'miscellaneous/flint.png', 'flint'],
    [MEDIA_URL + 'miscellaneous/leather.png', 'leather'],
    [MEDIA_URL + 'miscellaneous/rabbit_hide.png', 'rabbit_hide'],
    [MEDIA_URL + 'miscellaneous/clay_ball.png', 'clay_ball'],
    [MEDIA_URL + 'miscellaneous/sugar.png', 'sugar'],
    [MEDIA_URL + 'miscellaneous/brick.png', 'brick'],
    [MEDIA_URL + 'miscellaneous/netherbrick.png', 'netherbrick'],
    [MEDIA_URL + 'miscellaneous/quartz.png', 'quartz'],
    [MEDIA_URL + 'miscellaneous/paper.png', 'paper'],
    [MEDIA_URL + 'miscellaneous/book_normal.png', 'book'],
    [MEDIA_URL + 'miscellaneous/arrow.png', 'arrow'],
    [MEDIA_URL + 'miscellaneous/bone.png', 'bone'],
    [MEDIA_URL + 'miscellaneous/map_empty.png', 'emptymap'],
    [MEDIA_URL + 'miscellaneous/map_empty.png', 'emptymap,2'],
    [MEDIA_URL + 'miscellaneous/reeds.png', 'reeds'],
    [MEDIA_URL + 'miscellaneous/wheat.png', 'wheat'],
    [MEDIA_URL + 'miscellaneous/seeds_wheat.png', 'wheat_seeds'],
    [MEDIA_URL + 'miscellaneous/seeds_pumpkin.png', 'pumpkin_seeds'],
    [MEDIA_URL + 'miscellaneous/seeds_melon.png', 'melon_seeds'],
    [MEDIA_URL + 'miscellaneous/seeds_beetroot.png', 'beetroot_seeds'],
    [MEDIA_URL + 'miscellaneous/egg.png', 'egg'],
    [MEDIA_URL + 'miscellaneous/apple.png', 'apple'],
    [MEDIA_URL + 'miscellaneous/apple_golden.png', 'golden_apple'],
    [MEDIA_URL + 'miscellaneous/apple_enchanted.png', 'golden_apple,1'],
    [MEDIA_URL + 'miscellaneous/fish_raw.png', 'fish'],
    [MEDIA_URL + 'miscellaneous/fish_salmon_raw.png', 'salmon'],
    [MEDIA_URL + 'miscellaneous/fish_clownfish_raw.png', 'clownfish'],
    [MEDIA_URL + 'miscellaneous/fish_pufferfish_raw.png', 'pufferfish'],
    [MEDIA_URL + 'miscellaneous/fish_cooked.png', 'cooked_fish'],
    [MEDIA_URL + 'miscellaneous/fish_salmon_cooked.png', 'cooked_salmon'],
    [MEDIA_URL + 'miscellaneous/rotten_flesh.png', 'rotten_flesh'],
    [MEDIA_URL + 'miscellaneous/mushroom_stew.png', 'mushroom_stew'],
    [MEDIA_URL + 'miscellaneous/bread.png', 'bread'],
    [MEDIA_URL + 'miscellaneous/porkchop_raw.png', 'porkchop'],
    [MEDIA_URL + 'miscellaneous/porkchop_cooked.png', 'cooked_porkchop'],
    [MEDIA_URL + 'miscellaneous/chicken_raw.png', 'chicken'],
    [MEDIA_URL + 'miscellaneous/chicken_cooked.png', 'cooked_chicken'],
    [MEDIA_URL + 'miscellaneous/mutton_raw.png', 'muttonraw'],
    [MEDIA_URL + 'miscellaneous/mutton_cooked.png', 'muttoncooked'],
    [MEDIA_URL + 'miscellaneous/beef_raw.png', 'beef'],
    [MEDIA_URL + 'miscellaneous/beef_cooked.png', 'cooked_beef'],
    [MEDIA_URL + 'miscellaneous/melon.png', 'melon'],
    [MEDIA_URL + 'miscellaneous/carrot.png', 'carrot'],
    [MEDIA_URL + 'miscellaneous/potato.png', 'potato'],
    [MEDIA_URL + 'miscellaneous/potato_baked.png', 'baked_potato'],
    [MEDIA_URL + 'miscellaneous/potato_poisonous.png', 'poisonous_potato'],
    [MEDIA_URL + 'miscellaneous/beetroot.png', 'beetroot'],
    [MEDIA_URL + 'miscellaneous/beetroot_soup.png', 'beetroot_soup'],
    [MEDIA_URL + 'miscellaneous/cookie.png', 'cookie'],
    [MEDIA_URL + 'miscellaneous/pumpkin_pie.png', 'pumpkin_pie'],
    [MEDIA_URL + 'miscellaneous/rabbit_raw.png', 'rabbit'],
    [MEDIA_URL + 'miscellaneous/rabbit_cooked.png', 'cooked_rabbit'],
    [MEDIA_URL + 'miscellaneous/rabbit_stew.png', 'rabbit_stew'],
    [MEDIA_URL + 'miscellaneous/chorus_fruit.png', 'chorus_fruit'],
    [
      MEDIA_URL + 'miscellaneous/chorus_fruit_popped.png',
      'chorus_fruit_popped',
    ],
    [MEDIA_URL + 'miscellaneous/nether_star.png', 'netherstar'],
    [MEDIA_URL + 'miscellaneous/magma_cream.png', 'magma_cream'],
    [MEDIA_URL + 'miscellaneous/blaze_rod.png', 'blaze_rod'],
    [MEDIA_URL + 'miscellaneous/gold_nugget.png', 'gold_nugget'],
    [MEDIA_URL + 'miscellaneous/iron_nugget.png', 'iron_nugget'],
    [MEDIA_URL + 'miscellaneous/carrot_golden.png', 'golden_carrot'],
    [MEDIA_URL + 'miscellaneous/melon_speckled.png', 'speckled_melon'],
    [MEDIA_URL + 'miscellaneous/rabbit_foot.png', 'rabbit_foot'],
    [MEDIA_URL + 'miscellaneous/ghast_tear.png', 'ghast_tear'],
    [MEDIA_URL + 'miscellaneous/slimeball.png', 'slime_ball'],
    [MEDIA_URL + 'miscellaneous/blaze_powder.png', 'blaze_powder'],
    [MEDIA_URL + 'miscellaneous/nether_wart.png', 'nether_wart'],
    [MEDIA_URL + 'miscellaneous/gunpowder.png', 'gunpowder'],
    [MEDIA_URL + 'miscellaneous/glowstone_dust.png', 'glowstone_dust'],
    [MEDIA_URL + 'miscellaneous/spider_eye.png', 'spider_eye'],
    [
      MEDIA_URL + 'miscellaneous/spider_eye_fermented.png',
      'fermented_spider_eye',
    ],
    [MEDIA_URL + 'miscellaneous/dragons_breath.png', 'dragon_breath'],
    [MEDIA_URL + 'miscellaneous/carrot_on_a_stick.png', 'carrotonastick'],
    [MEDIA_URL + 'miscellaneous/experience_bottle.png', 'experience_bottle'],
    [MEDIA_URL + 'miscellaneous/shulker_shell.png', 'shulker_shell'],
    [MEDIA_URL + 'miscellaneous/prismarine_shard.png', 'prismarine_shard'],
    [
      MEDIA_URL + 'miscellaneous/prismarine_crystals.png',
      'prismarine_crystals',
    ],
    [MEDIA_URL + 'miscellaneous/dye_powder_black.png', 'dye'],
    [MEDIA_URL + 'miscellaneous/dye_powder_gray.png', 'dye,8'],
    [MEDIA_URL + 'miscellaneous/dye_powder_silver.png', 'dye,7'],
    [MEDIA_URL + 'miscellaneous/dye_powder_white.png', 'dye,15'],
    [MEDIA_URL + 'miscellaneous/dye_powder_light_blue.png', 'dye,12'],
    [MEDIA_URL + 'miscellaneous/dye_powder_orange.png', 'dye,14'],
    [MEDIA_URL + 'miscellaneous/dye_powder_red.png', 'dye,1'],
    [MEDIA_URL + 'miscellaneous/dye_powder_blue.png', 'dye,4'],
    [MEDIA_URL + 'miscellaneous/dye_powder_purple.png', 'dye,5'],
    [MEDIA_URL + 'miscellaneous/dye_powder_magenta.png', 'dye,13'],
    [MEDIA_URL + 'miscellaneous/dye_powder_pink.png', 'dye,9'],
    [MEDIA_URL + 'miscellaneous/dye_powder_brown.png', 'dye,3'],
    [MEDIA_URL + 'miscellaneous/dye_powder_yellow.png', 'dye,11'],
    [MEDIA_URL + 'miscellaneous/dye_powder_lime.png', 'dye,10'],
    [MEDIA_URL + 'miscellaneous/dye_powder_green.png', 'dye,2'],
    [MEDIA_URL + 'miscellaneous/dye_powder_cyan.png', 'dye,6'],
  ],
  tools: [
    [MEDIA_URL + 'tools/rail_normal.png', 'rail'],
    [MEDIA_URL + 'tools/rail_golden.png', 'golden_rail'],
    [MEDIA_URL + 'tools/rail_detector.png', 'detector_rail'],
    [MEDIA_URL + 'tools/rail_activator.png', 'activator_rail'],
    [MEDIA_URL + 'tools/torch_on.png', 'torch'],
    [MEDIA_URL + 'tools/bucket_empty.png', 'bucket'],
    [MEDIA_URL + 'tools/bucket_milk.png', 'milk_bucket'],
    [MEDIA_URL + 'tools/bucket_water.png', 'water_bucket'],
    [MEDIA_URL + 'tools/bucket_lava.png', 'lava_bucket'],
    [MEDIA_URL + 'tools/tnt.png', 'tnt'],
    [MEDIA_URL + 'tools/lead.png', 'lead'],
    [MEDIA_URL + 'tools/name_tag.png', 'nametag'],
    [MEDIA_URL + 'tools/redstone_dust.png', 'redstone'],
    [MEDIA_URL + 'tools/bow_standby.png', 'bow'],
    [MEDIA_URL + 'tools/fishing_rod_uncast.png', 'fishing_rod'],
    [MEDIA_URL + 'tools/flint_and_steel.png', 'flint_and_steel'],
    [MEDIA_URL + 'tools/shears.png', 'shears'],
    [MEDIA_URL + 'tools/clock_item.png', 'clock'],
    [MEDIA_URL + 'tools/compass_item.png', 'compass'],
    [MEDIA_URL + 'tools/minecart_normal.png', 'minecart'],
    [MEDIA_URL + 'tools/minecart_chest.png', 'chest_minecart'],
    [MEDIA_URL + 'tools/minecart_hopper.png', 'hopper_minecart'],
    [MEDIA_URL + 'tools/minecart_tnt.png', 'tnt_minecart'],
    [MEDIA_URL + 'tools/boat.png', 'boat'],
    [MEDIA_URL + 'tools/boat_spruce.png', 'boat,1'],
    [MEDIA_URL + 'tools/boat_birch.png', 'boat,2'],
    [MEDIA_URL + 'tools/boat_jungle.png', 'boat,3'],
    [MEDIA_URL + 'tools/boat_acacia.png', 'boat,4'],
    [MEDIA_URL + 'tools/boat_darkoak.png', 'boat,5'],
    [MEDIA_URL + 'tools/saddle.png', 'saddle'],
    [MEDIA_URL + 'tools/leather_horse_armor.png', 'horsearmorleather'],
    [MEDIA_URL + 'tools/iron_horse_armor.png', 'horsearmoriron'],
    [MEDIA_URL + 'tools/gold_horse_armor.png', 'horsearmorgold'],
    [MEDIA_URL + 'tools/diamond_horse_armor.png', 'horsearmordiamond'],
    [MEDIA_URL + 'tools/flower_pot.png', 'flower_pot'],
    [MEDIA_URL + 'tools/egg_villager.png', 'spawn_egg,15'],
    [MEDIA_URL + 'tools/egg_chicken.png', 'spawn_egg,10'],
    [MEDIA_URL + 'tools/egg_cow.png', 'spawn_egg,11'],
    [MEDIA_URL + 'tools/egg_pig.png', 'spawn_egg,12'],
    [MEDIA_URL + 'tools/egg_sheep.png', 'spawn_egg,13'],
    [MEDIA_URL + 'tools/egg_wolf.png', 'spawn_egg,14'],
    [MEDIA_URL + 'tools/egg_polarbear.png', 'spawn_egg,28'],
    [MEDIA_URL + 'tools/egg_ocelot.png', 'spawn_egg,22'],
    [MEDIA_URL + 'tools/egg_mushroomcow.png', 'spawn_egg,16'],
    [MEDIA_URL + 'tools/egg_bat.png', 'spawn_egg,19'],
    [MEDIA_URL + 'tools/egg_rabbit.png', 'spawn_egg,18'],
    [MEDIA_URL + 'tools/egg_llama.png', 'spawn_egg,29'],
    [MEDIA_URL + 'tools/egg_horse.png', 'spawn_egg,23'],
    [MEDIA_URL + 'tools/egg_donkey.png', 'spawn_egg,24'],
    [MEDIA_URL + 'tools/egg_mule.png', 'spawn_egg,25'],
    [MEDIA_URL + 'tools/egg_skeletonhorse.png', 'spawn_egg,26'],
    [MEDIA_URL + 'tools/egg_zombiehorse.png', 'spawn_egg,27'],
    [MEDIA_URL + 'tools/egg_creeper.png', 'spawn_egg,33'],
    [MEDIA_URL + 'tools/egg_enderman.png', 'spawn_egg,38'],
    [MEDIA_URL + 'tools/egg_silverfish.png', 'spawn_egg,30'],
    [MEDIA_URL + 'tools/egg_skeleton.png', 'spawn_egg,34'],
    [MEDIA_URL + 'tools/egg_wither.png', 'spawn_egg,48'],
    [MEDIA_URL + 'tools/egg_stray.png', 'spawn_egg,46'],
    [MEDIA_URL + 'tools/egg_slime.png', 'spawn_egg,37'],
    [MEDIA_URL + 'tools/egg_spider.png', 'spawn_egg,35'],
    [MEDIA_URL + 'tools/egg_zombie.png', 'spawn_egg,32'],
    [MEDIA_URL + 'tools/egg_pigzombie.png', 'spawn_egg,36'],
    [MEDIA_URL + 'tools/egg_husk.png', 'spawn_egg,47'],
    [MEDIA_URL + 'tools/egg_squid.png', 'spawn_egg,17'],
    [MEDIA_URL + 'tools/egg_cave_spider.png', 'spawn_egg,40'],
    [MEDIA_URL + 'tools/egg_witch.png', 'spawn_egg,45'],
    [MEDIA_URL + 'tools/egg_guardian.png', 'spawn_egg,49'],
    [MEDIA_URL + 'tools/egg_elderguardian.png', 'spawn_egg,50'],
    [MEDIA_URL + 'tools/egg_endermite.png', 'spawn_egg,55'],
    [MEDIA_URL + 'tools/egg_lava_slime.png', 'spawn_egg,42'],
    [MEDIA_URL + 'tools/egg_ghast.png', 'spawn_egg,41'],
    [MEDIA_URL + 'tools/egg_blaze.png', 'spawn_egg,43'],
    [MEDIA_URL + 'tools/egg_shulker.png', 'spawn_egg,54'],
    [MEDIA_URL + 'tools/egg_vindicator.png', 'spawn_egg,57'],
    [MEDIA_URL + 'tools/egg_evoker.png', 'spawn_egg,104'],
    [MEDIA_URL + 'tools/egg_vex.png', 'spawn_egg,105'],
    [MEDIA_URL + 'tools/egg_npc.png', 'spawn_egg,51'],
    [MEDIA_URL + 'tools/fireball.png', 'fireball'],
    [MEDIA_URL + 'tools/wood_sword.png', 'wooden_sword'],
    [MEDIA_URL + 'tools/wood_hoe.png', 'wooden_hoe'],
    [MEDIA_URL + 'tools/wood_shovel.png', 'wooden_shovel'],
    [MEDIA_URL + 'tools/wood_pickaxe.png', 'wooden_pickaxe'],
    [MEDIA_URL + 'tools/wood_axe.png', 'wooden_axe'],
    [MEDIA_URL + 'tools/stone_sword.png', 'stone_sword'],
    [MEDIA_URL + 'tools/stone_hoe.png', 'stone_hoe'],
    [MEDIA_URL + 'tools/stone_shovel.png', 'stone_shovel'],
    [MEDIA_URL + 'tools/stone_pickaxe.png', 'stone_pickaxe'],
    [MEDIA_URL + 'tools/stone_axe.png', 'stone_axe'],
    [MEDIA_URL + 'tools/iron_sword.png', 'iron_sword'],
    [MEDIA_URL + 'tools/iron_hoe.png', 'iron_hoe'],
    [MEDIA_URL + 'tools/iron_shovel.png', 'iron_shovel'],
    [MEDIA_URL + 'tools/iron_pickaxe.png', 'iron_pickaxe'],
    [MEDIA_URL + 'tools/iron_axe.png', 'iron_axe'],
    [MEDIA_URL + 'tools/diamond_sword.png', 'diamond_sword'],
    [MEDIA_URL + 'tools/diamond_hoe.png', 'diamond_hoe'],
    [MEDIA_URL + 'tools/diamond_shovel.png', 'diamond_shovel'],
    [MEDIA_URL + 'tools/diamond_pickaxe.png', 'diamond_pickaxe'],
    [MEDIA_URL + 'tools/diamond_axe.png', 'diamond_axe'],
    [MEDIA_URL + 'tools/gold_sword.png', 'golden_sword'],
    [MEDIA_URL + 'tools/gold_hoe.png', 'golden_hoe'],
    [MEDIA_URL + 'tools/gold_shovel.png', 'golden_shovel'],
    [MEDIA_URL + 'tools/gold_pickaxe.png', 'golden_pickaxe'],
    [MEDIA_URL + 'tools/gold_axe.png', 'golden_axe'],
    [MEDIA_URL + 'tools/leather_helmet.png', 'leather_helmet'],
    [MEDIA_URL + 'tools/leather_chestplate.png', 'leather_chestplate'],
    [MEDIA_URL + 'tools/leather_leggings.png', 'leather_leggings'],
    [MEDIA_URL + 'tools/leather_boots.png', 'leather_boots'],
    [MEDIA_URL + 'tools/chainmail_helmet.png', 'chainmail_helmet'],
    [MEDIA_URL + 'tools/chainmail_chestplate.png', 'chainmail_chestplate'],
    [MEDIA_URL + 'tools/chainmail_leggings.png', 'chainmail_leggings'],
    [MEDIA_URL + 'tools/chainmail_boots.png', 'chainmail_boots'],
    [MEDIA_URL + 'tools/iron_helmet.png', 'iron_helmet'],
    [MEDIA_URL + 'tools/iron_chestplate.png', 'iron_chestplate'],
    [MEDIA_URL + 'tools/iron_leggings.png', 'iron_leggings'],
    [MEDIA_URL + 'tools/iron_boots.png', 'iron_boots'],
    [MEDIA_URL + 'tools/diamond_helmet.png', 'diamond_helmet'],
    [MEDIA_URL + 'tools/diamond_chestplate.png', 'diamond_chestplate'],
    [MEDIA_URL + 'tools/diamond_leggings.png', 'diamond_leggings'],
    [MEDIA_URL + 'tools/diamond_boots.png', 'diamond_boots'],
    [MEDIA_URL + 'tools/gold_helmet.png', 'golden_helmet'],
    [MEDIA_URL + 'tools/gold_chestplate.png', 'golden_chestplate'],
    [MEDIA_URL + 'tools/gold_leggings.png', 'golden_leggings'],
    [MEDIA_URL + 'tools/gold_boots.png', 'golden_boots'],
    [MEDIA_URL + 'tools/elytra.png', 'elytra'],
    [MEDIA_URL + 'tools/totem.png', 'totem'],
    [MEDIA_URL + 'tools/lever.png', 'lever'],
    [MEDIA_URL + 'tools/redstone_lamp.png', 'redstone_lamp'],
    [MEDIA_URL + 'tools/redstone_torch.png', 'redstone_torch'],
    [MEDIA_URL + 'tools/wooden_pressure_plate.png', 'wooden_pressure_plate'],
    [MEDIA_URL + 'tools/stone_pressure_plate.png', 'stone_pressure_plate'],
    [
      MEDIA_URL + 'tools/light_weighted_pressure_plate.png',
      'light_weighted_pressure_plate',
    ],
    [
      MEDIA_URL + 'tools/heavy_weighted_pressure_plate.png',
      'heavy_weighted_pressure_plate',
    ],
    [MEDIA_URL + 'tools/wooden_button.png', 'wooden_button'],
    [MEDIA_URL + 'tools/stone_button.png', 'stone_button'],
    [MEDIA_URL + 'tools/daylight_detector.png', 'daylight_detector'],
    [MEDIA_URL + 'tools/tripwire_hook.png', 'tripwire_hook'],
    [MEDIA_URL + 'tools/repeater.png', 'repeater'],
    [MEDIA_URL + 'tools/comparator.png', 'comparator'],
    [MEDIA_URL + 'tools/dispenser.png', 'dispenser'],
    [MEDIA_URL + 'tools/dropper.png', 'dropper'],
    [MEDIA_URL + 'tools/piston.png', 'piston'],
    [MEDIA_URL + 'tools/sticky_piston.png', 'sticky_piston'],
    [MEDIA_URL + 'tools/observer.png', 'observer'],
    [MEDIA_URL + 'tools/hopper.png', 'hopper'],
    [MEDIA_URL + 'tools/snowball.png', 'snowball'],
    [MEDIA_URL + 'tools/ender_pearl.png', 'ender_pearl'],
    [MEDIA_URL + 'tools/ender_eye.png', 'ender_eye'],
    [MEDIA_URL + 'tools/camera.png', 'camera'],
    [MEDIA_URL + 'tools/book_portfolio.png', 'portfolio'],
  ],
  blocks: [
    [MEDIA_URL + 'blocks/air.png', 'air'],
    [MEDIA_URL + 'blocks/cobblestone.png', 'cobblestone'],
    [MEDIA_URL + 'blocks/stonebrick.png', 'stonebrick'],
    [MEDIA_URL + 'blocks/mossy_stonebrick.png', 'stonebrick,1'],
    [MEDIA_URL + 'blocks/cracked_stonebrick.png', 'stonebrick,2'],
    [MEDIA_URL + 'blocks/chiseled_stonebrick.png', 'stonebrick,3'],
    [MEDIA_URL + 'blocks/mossy_cobblestone.png', 'mossy_cobblestone'],
    [MEDIA_URL + 'blocks/planks_oak.png', 'planks'],
    [MEDIA_URL + 'blocks/planks_spruce.png', 'planks,1'],
    [MEDIA_URL + 'blocks/planks_birch.png', 'planks,2'],
    [MEDIA_URL + 'blocks/planks_jungle.png', 'planks,3'],
    [MEDIA_URL + 'blocks/planks_acacia.png', 'planks,4'],
    [MEDIA_URL + 'blocks/planks_dark_oak.png', 'planks,5'],
    [MEDIA_URL + 'blocks/bricks.png', 'brick_block'],
    [MEDIA_URL + 'blocks/stone.png', 'stone'],
    [MEDIA_URL + 'blocks/granite.png', 'stone,1'],
    [MEDIA_URL + 'blocks/polished_granite.png', 'stone,2'],
    [MEDIA_URL + 'blocks/diorite.png', 'stone,3'],
    [MEDIA_URL + 'blocks/polished_diorite.png', 'stone,4'],
    [MEDIA_URL + 'blocks/andesite.png', 'stone,5'],
    [MEDIA_URL + 'blocks/polished_andesite.png', 'stone,6'],
    [MEDIA_URL + 'blocks/dirt.png', 'dirt'],
    [MEDIA_URL + 'blocks/podzol.png', 'podzol'],
    [MEDIA_URL + 'blocks/grass.png', 'grass'],
    [MEDIA_URL + 'blocks/mycelium.png', 'mycelium'],
    [MEDIA_URL + 'blocks/clay.png', 'clay'],
    [MEDIA_URL + 'blocks/hardened_clay.png', 'hardened_clay'],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_white.png',
      'stained_hardened_clay',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_silver.png',
      'stained_hardened_clay,8',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_gray.png',
      'stained_hardened_clay,7',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_black.png',
      'stained_hardened_clay,15',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_brown.png',
      'stained_hardened_clay,12',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_red.png',
      'stained_hardened_clay,14',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_orange.png',
      'stained_hardened_clay,1',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_yellow.png',
      'stained_hardened_clay,4',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_lime.png',
      'stained_hardened_clay,5',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_green.png',
      'stained_hardened_clay,13',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_cyan.png',
      'stained_hardened_clay,9',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_light_blue.png',
      'stained_hardened_clay,3',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_blue.png',
      'stained_hardened_clay,11',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_purple.png',
      'stained_hardened_clay,10',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_magenta.png',
      'stained_hardened_clay,2',
    ],
    [
      MEDIA_URL + 'blocks/stained_hardened_clay_pink.png',
      'stained_hardened_clay,6',
    ],
    [MEDIA_URL + 'blocks/sandstone.png', 'sandstone'],
    [MEDIA_URL + 'blocks/chiseled_sandstone.png', 'sandstone,1'],
    [MEDIA_URL + 'blocks/smooth_sandstone.png', 'sandstone,1'],
    [MEDIA_URL + 'blocks/red_sandstone.png', 'red_sandstone'],
    [MEDIA_URL + 'blocks/chiseled_red_sandstone.png', 'red_sandstone,1'],
    [MEDIA_URL + 'blocks/smooth_red_sandstone.png', 'red_sandstone,1'],
    [MEDIA_URL + 'blocks/sand.png', 'sand'],
    [MEDIA_URL + 'blocks/red_sand.png', 'sand,1'],
    [MEDIA_URL + 'blocks/gravel.png', 'gravel'],
    [MEDIA_URL + 'blocks/log_oak.png', 'log'],
    [MEDIA_URL + 'blocks/log_spruce.png', 'log,1'],
    [MEDIA_URL + 'blocks/log_birch.png', 'log,2'],
    [MEDIA_URL + 'blocks/log_jungle.png', 'log,3'],
    [MEDIA_URL + 'blocks/log_acacia.png', 'log2,0'],
    [MEDIA_URL + 'blocks/log_dark_oak.png', 'log2,1'],
    [MEDIA_URL + 'blocks/nether_brick.png', 'nether_brick'],
    [MEDIA_URL + 'blocks/netherrack.png', 'netherrack'],
    [MEDIA_URL + 'blocks/soul_sand.png', 'soul_sand'],
    [MEDIA_URL + 'blocks/bedrock.png', 'bedrock'],
    [MEDIA_URL + 'blocks/stone_stairs.png', 'stone_stairs'],
    [MEDIA_URL + 'blocks/oak_stairs.png', 'oak_stairs'],
    [MEDIA_URL + 'blocks/spruce_stairs.png', 'spruce_stairs'],
    [MEDIA_URL + 'blocks/birch_stairs.png', 'birch_stairs'],
    [MEDIA_URL + 'blocks/jungle_stairs.png', 'jungle_stairs'],
    [MEDIA_URL + 'blocks/acacia_stairs.png', 'acacia_stairs'],
    [MEDIA_URL + 'blocks/dark_oak_stairs.png', 'dark_oak_stairs'],
    [MEDIA_URL + 'blocks/brick_stairs.png', 'brick_stairs'],
    [MEDIA_URL + 'blocks/sandstone_stairs.png', 'sandstone_stairs'],
    [MEDIA_URL + 'blocks/red_sandstone_stairs.png', 'red_sandstone_stairs'],
    [MEDIA_URL + 'blocks/stone_brick_stairs.png', 'stone_brick_stairs'],
    [MEDIA_URL + 'blocks/nether_brick_stairs.png', 'nether_brick_stairs'],
    [MEDIA_URL + 'blocks/quartz_stairs.png', 'quartz_stairs'],
    [MEDIA_URL + 'blocks/purpur_stairs.png', 'purpur_stairs'],
    [MEDIA_URL + 'blocks/stone_slab.png', 'stone_slab'],
    [MEDIA_URL + 'blocks/cobblestone_slab.png', 'stone_slab,3'],
    [MEDIA_URL + 'blocks/wooden_slab_oak.png', 'wooden_slab'],
    [MEDIA_URL + 'blocks/wooden_slab_spruce.png', 'wooden_slab,1'],
    [MEDIA_URL + 'blocks/wooden_slab_birch.png', 'wooden_slab,2'],
    [MEDIA_URL + 'blocks/wooden_slab_jungle.png', 'wooden_slab,3'],
    [MEDIA_URL + 'blocks/wooden_slab_acacia.png', 'wooden_slab,4'],
    [MEDIA_URL + 'blocks/wooden_slab_dark_oak.png', 'wooden_slab,5'],
    [MEDIA_URL + 'blocks/brick_slab.png', 'stone_slab,4'],
    [MEDIA_URL + 'blocks/sandstone_slab.png', 'stone_slab,1'],
    [MEDIA_URL + 'blocks/red_sandstone_slab.png', 'stone_slab2'],
    [MEDIA_URL + 'blocks/stonebrick_slab.png', 'stone_slab,5'],
    [MEDIA_URL + 'blocks/netherbrick_slab.png', 'stone_slab,7'],
    [MEDIA_URL + 'blocks/quartz_slab.png', 'stone_slab,6'],
    [MEDIA_URL + 'blocks/purpur_slab.png', 'stone_slab2,1'],
    [MEDIA_URL + 'blocks/quartz_block.png', 'quartz_block'],
    [MEDIA_URL + 'blocks/pillar_quartz_block.png', 'quartz_block,2'],
    [MEDIA_URL + 'blocks/chiseled_quartz_block.png', 'quartz_block,1'],
    [MEDIA_URL + 'blocks/prismarine.png', 'prismarine'],
    [MEDIA_URL + 'blocks/prismarinebrick.png', 'prismarine,2'],
    [MEDIA_URL + 'blocks/dark_prismarine.png', 'prismarine,1'],
    [MEDIA_URL + 'blocks/purpur_block.png', 'purpur_block'],
    [MEDIA_URL + 'blocks/pillar_purpur_block.png', 'purpur_block,2'],
    [MEDIA_URL + 'blocks/coal_ore.png', 'coal_ore'],
    [MEDIA_URL + 'blocks/iron_ore.png', 'iron_ore'],
    [MEDIA_URL + 'blocks/gold_ore.png', 'gold_ore'],
    [MEDIA_URL + 'blocks/diamond_ore.png', 'diamond_ore'],
    [MEDIA_URL + 'blocks/lapis_ore.png', 'lapis_ore'],
    [MEDIA_URL + 'blocks/redstone_ore.png', 'redstone_ore'],
    [MEDIA_URL + 'blocks/emerald_ore.png', 'emerald_ore'],
    [MEDIA_URL + 'blocks/quartz_ore.png', 'quartz_ore'],
    [MEDIA_URL + 'blocks/obsidian.png', 'obsidian'],
    [MEDIA_URL + 'blocks/ice.png', 'ice'],
    [MEDIA_URL + 'blocks/ice_packed.png', 'packed_ice'],
    [MEDIA_URL + 'blocks/snow.png', 'snow'],
    [MEDIA_URL + 'blocks/end_bricks.png', 'end_bricks'],
    [MEDIA_URL + 'blocks/end_stone.png', 'end_stone'],
    [MEDIA_URL + 'blocks/allow.png', 'allow'],
    [MEDIA_URL + 'blocks/deny.png', 'deny'],
    [MEDIA_URL + 'blocks/border_block.png', 'border_block'],
  ],
});
