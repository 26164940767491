module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% var i18n = require(\'../../locale\'); %>\n<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar image = locals.image;\n%>\n<h1 class="minecraft-big-yellow-header" id="getting-started-header"><%= i18n.houseSelectLetsBuild() %></h1>\n\n<h2 id="select-house-text"><%= i18n.houseSelectChooseFloorPlan() %></h2>\n\n<div id="choose-house-container">\n  <div class="minecraft-house" id="choose-house-a">\n    <h1 class="minecraft-big-yellow-header"><%= i18n.houseSelectEasy() %></h1>\n    <div class="house-outline-container">\n      <div class="house-photo" id="house-a-picture"></div>\n    </div>\n    <div class="choose-house-button"><%= i18n.selectChooseButton() %></div>\n  </div>\n  <div class="minecraft-house" id="choose-house-b">\n    <h1 class="minecraft-big-yellow-header"><%= i18n.houseSelectMedium() %></h1>\n    <div class="house-outline-container">\n      <div class="house-photo" id="house-b-picture"></div>\n    </div>\n    <div class="choose-house-button"><%= i18n.selectChooseButton() %></div>\n  </div>\n  <div class="minecraft-house" id="choose-house-c">\n    <h1 class="minecraft-big-yellow-header"><%= i18n.houseSelectHard() %></h1>\n    <div class="house-outline-container">\n      <div class="house-photo" id="house-c-picture"></div>\n    </div>\n    <div class="choose-house-button"><%= i18n.selectChooseButton() %></div>\n  </div>\n</div>\n\n<div id="close-house-select"></div>\n', __filename = "src/craft/simple/dialogs/houseSelection.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var i18n = require("../../locale");
        __append("\n");
        __line = 2;
        var image = locals.image;
        __line = 7;
        __append('\n<h1 class="minecraft-big-yellow-header" id="getting-started-header">');
        __line = 8;
        __append(escapeFn(i18n.houseSelectLetsBuild()));
        __append('</h1>\n\n<h2 id="select-house-text">');
        __line = 10;
        __append(escapeFn(i18n.houseSelectChooseFloorPlan()));
        __append('</h2>\n\n<div id="choose-house-container">\n  <div class="minecraft-house" id="choose-house-a">\n    <h1 class="minecraft-big-yellow-header">');
        __line = 14;
        __append(escapeFn(i18n.houseSelectEasy()));
        __append('</h1>\n    <div class="house-outline-container">\n      <div class="house-photo" id="house-a-picture"></div>\n    </div>\n    <div class="choose-house-button">');
        __line = 18;
        __append(escapeFn(i18n.selectChooseButton()));
        __append('</div>\n  </div>\n  <div class="minecraft-house" id="choose-house-b">\n    <h1 class="minecraft-big-yellow-header">');
        __line = 21;
        __append(escapeFn(i18n.houseSelectMedium()));
        __append('</h1>\n    <div class="house-outline-container">\n      <div class="house-photo" id="house-b-picture"></div>\n    </div>\n    <div class="choose-house-button">');
        __line = 25;
        __append(escapeFn(i18n.selectChooseButton()));
        __append('</div>\n  </div>\n  <div class="minecraft-house" id="choose-house-c">\n    <h1 class="minecraft-big-yellow-header">');
        __line = 28;
        __append(escapeFn(i18n.houseSelectHard()));
        __append('</h1>\n    <div class="house-outline-container">\n      <div class="house-photo" id="house-c-picture"></div>\n    </div>\n    <div class="choose-house-button">');
        __line = 32;
        __append(escapeFn(i18n.selectChooseButton()));
        __append('</div>\n  </div>\n</div>\n\n<div id="close-house-select"></div>\n');
        __line = 37;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}