module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% var i18n = require(\'../../locale\'); %>\n<h1 class="minecraft-big-yellow-header" id="import-header"><%= i18n.importShareLinkHeader() %></h1>\n\n<h2><%= i18n.importShareLinkBody() %></h2>\n\n<input type="text" id="share-link" placeholder="Example: https://studio.code.org/c/999999999" />\n<div class="button" id="import-button"><%= i18n.import() %></div>\n\n<br />\n\n<div class="button" id="cancel-button"><%= i18n.cancel() %></div>\n\n<div id="close-popup"></div>\n', __filename = "src/craft/code-connection/dialogs/importFromShareLink.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var i18n = require("../../locale");
        __append('\n<h1 class="minecraft-big-yellow-header" id="import-header">');
        __line = 2;
        __append(escapeFn(i18n.importShareLinkHeader()));
        __append("</h1>\n\n<h2>");
        __line = 4;
        __append(escapeFn(i18n.importShareLinkBody()));
        __append('</h2>\n\n<input type="text" id="share-link" placeholder="Example: https://studio.code.org/c/999999999" />\n<div class="button" id="import-button">');
        __line = 7;
        __append(escapeFn(i18n.import()));
        __append('</div>\n\n<br />\n\n<div class="button" id="cancel-button">');
        __line = 11;
        __append(escapeFn(i18n.cancel()));
        __append('</div>\n\n<div id="close-popup"></div>\n');
        __line = 14;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}